
import { defineComponent } from "vue";
import { Parallax, Carousel, MasonryGallery } from "@/components";
import { eventsCollection } from "@/plugins/firebase";
import { EventItem } from "@/apiServices/interface";
import { timestampToDate } from "@/plugins/helpers/timeHelpers";
import ApiController from "@/apiServices/ApiController";

export default defineComponent({
  name: "EventsHistory",
  components: { Parallax, Carousel, MasonryGallery },
  data() {
    return {
      eventslist: [] as EventItem[],
      pendingRequest: false,
      pageLimit: 9,
      eventsItemCounter: 9,
      lastestDoc: null as any,
      endOfList: false,
      timestampToDate
    };
  },
  methods: {
    handleScroll() {
      const { scrollTop, offsetHeight } = document.documentElement;
      const { innerHeight } = window;
      const bottomOfWindow = Math.round(scrollTop) + innerHeight === offsetHeight;

      if (bottomOfWindow) {
        this.getEventsItems();
      }
    },
    async getEventsItems() {
      if (this.pendingRequest || this.endOfList) {
        return;
      }
      this.pendingRequest = true;
      const eventsItems = await ApiController.events.getEventItemList(
        this.pageLimit,
        this.lastestDoc
      );

      eventsItems?.docs.map(doc =>
        this.eventslist.push(({ id: doc.id, ...doc?.data() } as unknown) as EventItem)
      );

      if (!eventsItems?.docs.length || eventsItems?.docs.length < this.pageLimit) {
        this.endOfList = true;
        this.pendingRequest = false;
        return;
      }
      this.eventsItemCounter += this.pageLimit;
      this.pendingRequest = false;
    }
  },

  watch: {
    eventsItemCounter: async function() {
      if (this.eventslist.length) {
        const latest = this.eventslist[this.eventslist.length - 1].id;
        this.lastestDoc = await eventsCollection.doc(latest).get();
      }
    }
  },
  created() {
    this.getEventsItems();
    window.addEventListener("scroll", this.handleScroll);
  },
  beforeUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }
});
