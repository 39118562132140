
import { defineComponent, ref } from "vue";
import { Parallax, Carousel } from "@/components";
import { EventItem } from "@/apiServices/interface";
import { timestampToDate } from "@/plugins/helpers/timeHelpers";
import ApiController from "@/apiServices/ApiController";

export default defineComponent({
  name: "event",
  components: { Parallax, Carousel },
  setup() {
    const calendar = ref();
    const selectDate = (val: string) => {
      calendar.value.selectDate(val);
    };
    return {
      calendar,
      selectDate
    };
  },

  data() {
    return {
      eventList: [] as EventItem[],
      pendingRequest: false,
      pageLimit: 9,
      eventsItemCounter: 9,
      lastestDoc: null as any,
      endOfList: false,
      calendarMonth: "",
      timestampToDate
    };
  },
  methods: {
    setCalendarDate(date: string) {
      this.calendarMonth = date;
    },
    isEventDay(date: Date) {
      return this.eventList.find(event => timestampToDate(event.eventDate) === date.toDateString());
    },
    handleScroll() {
      const { scrollTop, offsetHeight } = document.documentElement;
      const { innerHeight } = window;
      const bottomOfWindow = Math.round(scrollTop) + innerHeight === offsetHeight;

      if (bottomOfWindow) {
        this.getEvents();
      }
    },
    async getEvents() {
      if (this.pendingRequest) {
        return;
      }
      this.pendingRequest = true;

      const eventItems = await ApiController.events.getSpecifiedMonthEvents(this.calendarMonth);

      if (eventItems?.docs.length) {
        eventItems.docs.map(doc =>
          this.eventList.push(({ id: doc.id, ...doc.data() } as unknown) as EventItem)
        );
      }
      this.pendingRequest = false;
    }
  },

  watch: {
    calendarMonth(next, current) {
      if (next !== current) {
        this.getEvents();
      }
    }
  },
  mounted() {
    this.getEvents();
    window.addEventListener("scroll", this.handleScroll);
  },
  beforeUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }
});
