
import { defineComponent } from "vue";
import { Parallax, Carousel, AchievementCounter } from "@/components";
import { EventItem } from "@/apiServices/interface";
import { storage } from "@/plugins/firebase";
import { timestampToDate } from "@/plugins/helpers/timeHelpers";
import ApiController from "@/apiServices/ApiController";

export default defineComponent({
  name: "EventDetail",
  components: { Parallax, Carousel, AchievementCounter },
  props: ["itemId"],
  data() {
    return {
      eventDetail: {} as EventItem,
      eventImages: [] as string[],
      pendingRequest: false,
      timestampToDate
    };
  },
  methods: {
    async getEventItem(eventItemId: string) {
      if (this.pendingRequest) {
        return;
      }
      this.pendingRequest = true;
      const eventItem = await ApiController.events.getEventItem(eventItemId);

      if (eventItem) {
        this.eventDetail = ({ ...eventItem, id: eventItemId } as unknown) as EventItem;
      }
      this.pendingRequest = false;
    },
    async getImages(eventItemId: string) {
      const storageRef = storage.ref().child(`events/${eventItemId}`);
      const itemList = await storageRef.listAll();
      itemList.items.forEach(async e => this.eventImages.push(await e.getDownloadURL()));
    }
  },

  created() {
    this.getEventItem(this.itemId);
    this.getImages(this.itemId);
  },
  watch: {
    itemId(updatedId) {
      this.getEventItem(updatedId);
      this.getImages(updatedId);
    }
  }
});
